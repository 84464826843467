import cube from './images/perfect-loop-cube.gif';
import './App.css';

function App() {
  return (
    <div className="App" color='black'>
      <header className="App-header">
        <img src={cube} className="App-logo" alt="logo" />
        <p>
          Currently <code>Refactoring</code> and Making Changes.
        </p>
        <p>
          Will Be Back Soon!
        </p>
      </header>
    </div>
  );
}

export default App;
